<template>
  <div class="page-folder">
    <div class="my-page">
      <div class="list-box">
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getDataList"
            offset="10"
          >
            <div
              class="my-card"
              v-for="(item, i) in dataList"
              :key="i"
              @click="handleClick(item)"
            >
              <div class="content-box">
                <div>
                  加班开始时间：<span>{{ item.BeginDate }}</span>
                </div>
                <div>
                  加班结束时间：<span>{{ item.EndDate }}</span>
                </div>
                <div>
                  加班天数：<span>{{ item.OverTimeDays }}</span>
                </div>
                <div>
                  审核人：<span>{{ item.auditUserName }}</span>
                </div>
              </div>
              <!-- 审核状态 -->
              <div
                v-if="item.OverTimeStateName === '未审核'"
                class="confirm-box"
              ></div>
              <div
                v-if="item.OverTimeStateName === '通过'"
                class="confirm-box type1"
              ></div>
              <div
                v-if="item.OverTimeStateName === '驳回'"
                class="confirm-box type2"
              ></div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <!-- 图片预览 -->
    <van-image-preview v-model="imgShow" :images="images"> </van-image-preview>
    <!-- 新增悬浮按钮 -->
    <!-- <div v-if="userType === '2'" class="add-btn" @click="add">+</div> -->
  </div>
</template>

<script>
import { Button, List, PullRefresh, Image, ImagePreview } from 'vant'
export default {
  components: {
    [Button.name]: Button,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Image.name]: Image,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  data () {
    return {
      userType: window.localStorage.getItem('UserType'),
      loading: false,
      finished: false, // false
      refreshing: false,
      dataList: [],
      page: 0, // 页码
      limit: 10, // 每页条数
      imgShow: false,
      images: []
    }
  },
  mounted () {
    // 空白时候自动触发
    // this.getDataList()
  },
  methods: {
    getDataList () {
      this.page++
      this.getList()
    },
    getList () {
      const that = this
      that.$axios
        .get('/api/OverTime/MyList', {
          page: this.page, // 页码
          limit: this.limit // 每页条数
        })
        .then(res => {
          if (res.code === 200) {
            that.refreshing = false
            that.loading = false
            that.dataList = that.dataList.concat(res.data)
            if (that.dataList.length >= res.count) {
              that.finished = true
            }
          } else {
            that.$toast.fail(res.msg || '操作失败')
          }
        })
    },
    // 下拉刷新
    onRefresh () {
      this.page = 0
      // 清空列表数据
      this.finished = false
      // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      this.dataList = []
      this.getDataList()
    },
    handleClick (item) {
      if (item.OverTimeStateName === '未审核') {
        this.$router.push({
          path: 'OverTimeMyEdit',
          query: {
            id: item.ID
          }
        })
      } else {
        this.$router.push({
          path: 'OverTimeMyView',
          query: {
            id: item.ID
          }
        })
      }
    },
    openOrClose (e) {
      const dom = this.$jq(e.currentTarget).find('i')
      if (dom.hasClass('i-down')) {
        dom.removeClass('i-down')
        dom
          .parent()
          .parent()
          .parent()
          .parent()
          .siblings('.card-bottom')
          .show()
      } else {
        dom.addClass('i-down')
        dom
          .parent()
          .parent()
          .parent()
          .parent()
          .siblings('.card-bottom')
          .hide()
      }
    },
    add () {
      this.$router.push('LeaveTeacherMyAdd')
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
