<template>
	<div class="page-leaveteacher">
		<div class="tab-box" style="background-color: #EDEDED;justify-content: center">
			<div class="tab-item" v-for="(num, index) in tabs" :key="index" :class="{ active: index === tabNum }"
				@click="tabClick(index)" style="margin: 0 50px;">
				{{ num }}
			</div>
		</div>
		<add v-if="tabNum === 0" @choose="chooseItem" />
		<my v-if="tabNum === 1" />
		<!-- <audit v-if="tabNum === 1" /> -->
	</div>
</template>

<script>
	import {
		Icon,
		Image,
		ImagePreview,
		Tabbar,
		TabbarItem
	} from "vant";
	import Add from "./My/Add";
	import My from "./My/List";
	// import Audit from './Audit/List'

	export default {
		components: {
			My,
			Add,
			[Tabbar.name]: Tabbar,
			[TabbarItem.name]: TabbarItem,
			// Audit,
		},
		data() {
			return {
				tabs: ["加班申请", "加班记录"],
				tabNum: 0,
				count: 0,
			};
		},
		mounted() {
			// this.getUser()
			if (this.$route.query.active) {
				this.tabNum = Number(this.$route.query.active);
			}
			this._getPending();
		},
		methods: {
			tabClick(index) {
				this.tabNum = index;
			},
			_getPending() {
				this.$axios.get("/api/LeaveForChildren/GetPendingCount").then((res) => {
					if (res.code === 200) {
						this.count = res.data;
					}
				});
			},
			chooseItem() {
				this.tabNum = 1;
			},
		},
	};
</script>
<style lang="less" scoped>
	.tabbar-top {
		.img {
			display: flex;
			align-items: center;
			justify-content: center
		}

		.text {
			margin-top: 10px
		}

		/deep/.van-tabbar-item__icon {
			left: 20px
		}
	}

	@import "./index.less";
</style>
